// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.NotificationsView-module__wrap--3Xmep {\n  display: flex;\n  margin-right: 15px;\n  cursor: pointer;\n}\n@media (min-width: 1200px) {\n  .NotificationsView-module__wrap--3Xmep {\n    margin-right: 0;\n    margin-left: 40px;\n  }\n}\n.NotificationsView-module__wrap--3Xmep .ant-badge-count {\n  background: #fff;\n  color: #0E3F5A;\n}\n", "",{"version":3,"sources":["webpack://src/containers/Notifications/NotificationsView.module.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAD7F;EACE,aAAA;EACA,kBAAA;EACA,eAAA;AAGF;AADE;EAAA;IACE,eAAA;IACA,iBAAA;EAIF;AACF;AAZA;EAWI,gBAAA;EACA,cAAA;AAIJ","sourcesContent":["@import \"../../theme/variables\";\n\n.wrap {\n  display: flex;\n  margin-right: 15px;\n  cursor: pointer;\n\n  @media @xl {\n    margin-right: 0;\n    margin-left: 40px;\n  }\n\n  :global(.ant-badge-count) {\n    background: @white;\n    color: @list-divider-color;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "NotificationsView-module__wrap--3Xmep"
};
export default ___CSS_LOADER_EXPORT___;
