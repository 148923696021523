import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ProductsState,
  Strategy,
  StrategyChartData,
  Index,
  IndexChartData,
} from './types';

const strategies = JSON.parse(
  localStorage.getItem('strategies') || '[]'
) as Strategy[];
const indexes = JSON.parse(localStorage.getItem('indexes') || '[]') as Index[];

export const initialState: ProductsState = {
  strategies,
  indexes,
  loading: false,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setStrategies(state, action: PayloadAction<Strategy[]>) {
      state.strategies = action.payload;
      localStorage.setItem('strategies', JSON.stringify(action.payload));
    },
    setIndexes(state, action: PayloadAction<Index[]>) {
      state.indexes = action.payload;
      localStorage.setItem('indexes', JSON.stringify(action.payload));
    },
    fetchStrategyChart(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    fetchStrategyChartSuccess(
      state,
      action: PayloadAction<{ symbol: string; chartData: StrategyChartData }>
    ) {
      state.strategies.some((product) => {
        if (product.symbol === action.payload.symbol) {
          product.chart = action.payload.chartData;
          return true;
        }
        return false;
      });
      state.loading = false;
    },
    fetchIndexChart(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    fetchIndexChartSuccess(
      state,
      action: PayloadAction<{ symbol: string; chartData: IndexChartData }>
    ) {
      state.indexes.some((product) => {
        if (product.symbol === action.payload.symbol) {
          product.chart = action.payload.chartData;
          return true;
        }
        return false;
      });
      state.loading = false;
    },
  },
});

export const productsActions = productsSlice.actions;
export const productsReducer = productsSlice.reducer;
