import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import cx from 'classnames';

import { modalActions } from '../../../ModalProvider/slice';
import { routes } from '../../../../config/routes';
import { useAppDispatch } from '../../../../redux/store';

import s from './NotVerifiedUser.module.less';
import attentionIcon from '../../../../assets/icons/attention.svg';

type Props = {
  text: string;
  className?: string;
};

const NotVerifiedUser = ({ text, className }: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  return (
    <div className={cx(s.container, className)}>
      <p>
        <img src={attentionIcon} alt="!" /> {text}
      </p>
      <Button
        type="primary"
        shape="round"
        onClick={() => {
          dispatch(modalActions.closeAll());
          history.push(routes.profile.path);
        }}
      >
        Verify Now
      </Button>
    </div>
  );
};

export default NotVerifiedUser;
