import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CurrenciesState, Currency } from './types';

const currencies = JSON.parse(
  localStorage.getItem('currencies') || '[]'
) as Currency[];

export const initialState: CurrenciesState = {
  data: currencies,
  loading: false,
  error: null,
};

const currenciesSlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    fetch(state) {
      state.loading = true;
    },
    fetchSuccess(state, action: PayloadAction<Currency[]>) {
      state.loading = false;
      state.data = action.payload;
      localStorage.setItem('currencies', JSON.stringify(action.payload));
    },
    fetchFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const currenciesActions = currenciesSlice.actions;
export const currenciesReducer = currenciesSlice.reducer;
