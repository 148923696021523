import React from 'react';

import appConfig from './app';
import { ReactComponent as HomeIcon } from '../assets/icons/home.svg';
import { ReactComponent as TransactionsIcon } from '../assets/icons/transactions.svg';
import { ReactComponent as PortfolioIcon } from '../assets/icons/portfolio.svg';
import { ReactComponent as RewardsIcon } from '../assets/icons/referrals.svg';
import { ReactComponent as EditIcon } from '../assets/icons/edit-profile.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { ReactComponent as WithdrawIcon } from '../assets/icons/withdraw.svg';
import { ReactComponent as HelpIcon } from '../assets/icons/help.svg';
import { ReactComponent as IndexesIcon } from '../assets/icons/products.svg';
import { ReactComponent as ProductsIcon } from '../assets/icons/percent.svg';

export type Route = {
  name: string;
  path: string;
  icon: React.FC | null;
};
type RouteKey =
  | 'overview'
  | 'transactions'
  | 'myPortfolio'
  | 'products'
  | 'rewards'
  | 'profile'
  | 'settings'
  | 'withdraw'
  | 'help'
  | 'home'
  | 'privacyPolicy'
  | 'indexes'
  | 'currentIndex';

type Routes = {
  [K in RouteKey]: Route;
};

export const routes: Routes = {
  overview: {
    name: 'Overview',
    path: '/',
    icon: HomeIcon,
  },
  transactions: {
    name: 'Transaction History',
    path: '/transactions',
    icon: TransactionsIcon,
  },
  myPortfolio: {
    name: 'My Portfolio',
    path: '/portfolio',
    icon: PortfolioIcon,
  },
  products: {
    name: 'Investment Products',
    path: '/products',
    icon: ProductsIcon,
  },
  rewards: {
    name: 'Referrals & Rewards',
    path: '/referrals-rewards',
    icon: RewardsIcon,
  },
  profile: {
    name: 'Edit Profile',
    path: '/user/profile',
    icon: EditIcon,
  },
  settings: {
    name: 'Account Settings',
    path: '/user/settings',
    icon: SettingsIcon,
  },
  withdraw: {
    name: 'Withdraw',
    path: '/user/withdraw',
    icon: WithdrawIcon,
  },
  help: {
    name: 'Help',
    path: `${appConfig.mainHost}/faq`,
    icon: HelpIcon,
  },
  home: {
    name: 'Home',
    path: appConfig.mainHost,
    icon: null,
  },
  privacyPolicy: {
    name: 'Legal & Privacy',
    path: `${appConfig.mainHost}/guide-central/legal`,
    icon: null,
  },
  indexes: {
    name: 'Indexes',
    path: '/indexes',
    icon: IndexesIcon,
  },
  currentIndex: {
    name: 'Index',
    path: '/indexes/:symbol',
    icon: null,
  },
};

export const getRouteName = (pathname: string) => {
  const route = Object.values(routes).find((r) => r.path === pathname);
  if (route) {
    return route.name;
  }
  return '';
};

export default routes;
