import React from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';

type Props = {
  text?: string;
  className?: string;
  placement?: TooltipPlacement;
};

const InfoTooltip: React.FC<Props> = ({
  children,
  text = '',
  className = '',
  placement = 'topRight',
}) => (
  <Tooltip title={children || text} placement={placement}>
    <InfoIcon className={className} />
  </Tooltip>
);

export default InfoTooltip;
