import { all, fork } from 'redux-saga/effects';

import productsSaga from '../containers/Products/saga';
import currenciesSaga from '../containers/Currencies/saga';
import portfolioSaga from '../containers/Portfolio/saga';
import userSaga from '../containers/User/saga';
import transactionsSaga from '../containers/Transactions/saga';
import fundsSaga from '../containers/Funds/saga';
import rewardsSaga from '../containers/ReferralsRewards/saga';
import gideCentralSaga from '../containers/GuideCentral/saga';
import notificationsSaga from '../containers/Notifications/saga';

function* rootSaga() {
  yield all([
    fork(productsSaga),
    fork(currenciesSaga),
    fork(portfolioSaga),
    fork(userSaga),
    fork(transactionsSaga),
    fork(fundsSaga),
    fork(rewardsSaga),
    fork(gideCentralSaga),
    fork(notificationsSaga),
  ]);
}

export { rootSaga };
