import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModalsState, ModalProps } from './types';

export const initialState: ModalsState = {
  addFunds: {
    visible: false,
  },
  tradeProducts: {
    visible: false,
  },
  confirmTx: {
    visible: false,
  },
  OTP: {
    visible: false,
  },
  goggle2FA: {
    visible: false,
  },
};

const modalProviderSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<ModalProps>) => {
      if (action.payload.name === 'confirmTx' && state.tradeProducts.visible) {
        state.tradeProducts.visible = false;
      }
      state[action.payload.name] = action.payload.data;
    },
    closeAll: () => initialState,
  },
});

export const modalReducer = modalProviderSlice.reducer;
export const modalActions = modalProviderSlice.actions;
