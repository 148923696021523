import React, { useEffect, useState } from 'react';
import { Avatar, Button, Drawer, Dropdown } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Route, routes } from '../../config/routes';
import {
  makeSelectAvatar,
  makeSelectUserName,
} from '../../containers/User/selectors';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ReactComponent as ArrowIcon } from '../../assets/icons/dropdown-arrow.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import Notifications from '../../containers/Notifications';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import addFundsIcon from '../../assets/icons/add-funds.svg';
import buySellIcon from '../../assets/icons/buy-sell.svg';
import config from '../../config/app';
import { eventGTM } from '../../services/analytics/gtm';
import { modalActions } from '../../containers/ModalProvider/slice';
import s from './UserMenu.module.less';
import { useMediaQueryContext } from '../../contexts/MediaQueryProvider';

const links: Route[] = [routes.profile, routes.settings, routes.withdraw];

const UserMenu = () => {
  const dispatch = useAppDispatch();
  const { isXl } = useMediaQueryContext();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies([config.tokenKey]);

  const userName = useAppSelector(makeSelectUserName());
  const avatarUrl = useAppSelector(makeSelectAvatar());

  const onVisible = () => {
    setVisible((prevState) => !prevState);
  };

  const onLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    removeCookies(config.tokenKey, { domain: config.domain });
    window.location.replace(`${config.mainHost}/login`);
  };

  const onClickAddFunds = () => {
    setVisible(false);
    dispatch(modalActions.open({ name: 'addFunds', data: { visible: true } }));
  };

  const onClickTradeProducts = () => {
    setVisible(false);
    dispatch(
      modalActions.open({ name: 'tradeProducts', data: { visible: true } })
    );
  };

  useEffect(() => {
    if (!isXl && visible) {
      setVisible(false);
    }
  }, [location, isXl]);

  const btns = (
    <div className={s.btns}>
      <Button type="primary" shape="round" onClick={onClickTradeProducts}>
        <img src={buySellIcon} alt="icon" />
        <span>Buy / Sell</span>
      </Button>
      <Button type="primary" ghost shape="round" onClick={onClickAddFunds}>
        <img src={addFundsIcon} alt="icon" />
        <span>ADD FUNDS</span>
      </Button>
    </div>
  );
  const avatar = (
    <Avatar
      size={34}
      className={s.avatar}
      icon={avatarUrl ? null : <UserIcon />}
      src={avatarUrl}
    />
  );

  if (!isXl) {
    return (
      <div className={s.wrap}>
        <Notifications />
        <button type="button" onClick={onVisible} className={s.user}>
          {avatar}
        </button>
        <Drawer
          visible={visible}
          placement="right"
          closable={false}
          maskClosable
          width={265}
          bodyStyle={{ overflow: 'inherit' }}
          onClose={() => setVisible(false)}
        >
          <div className={s.mobile_menu}>
            <button type="button" onClick={onVisible} className={s.user}>
              {avatar}
              <span className={s.user_name}>{userName}</span>
            </button>
            <ul>
              {links.map(({ name, path, icon: Icon = null }) => (
                <li key={name}>
                  <NavLink to={path} activeClassName={s.active}>
                    {Icon && <Icon />}
                    <span>{name}</span>
                  </NavLink>
                </li>
              ))}
              <li>
                <a href="/" onClick={onLogout}>
                  <LogoutIcon />
                  <span>Logout</span>
                </a>
              </li>
            </ul>
            {btns}
          </div>
        </Drawer>
      </div>
    );
  }

  const dropdownMenu = (
    <ul className={s.dropdown_menu}>
      {links.map(({ name, path, icon: Icon = null }) => (
        <li key={name}>
          <NavLink
            to={path}
            activeClassName={s.active}
            onClick={() => {
              eventGTM({
                event: 'Sitewide_Click_on_menu_items_in_Header',
                eventLabel: name,
              });
            }}
          >
            {Icon && <Icon />}
            <span>{name}</span>
          </NavLink>
        </li>
      ))}
      <li>
        <a
          href="/"
          onClick={(e) => {
            eventGTM({
              event: 'Sitewide_Click_on_menu_items_in_Header',
              eventLabel: 'Logout',
            });
            onLogout(e);
          }}
        >
          <LogoutIcon />
          <span>Logout</span>
        </a>
      </li>
    </ul>
  );

  return (
    <div className={s.wrap}>
      {btns}
      <Notifications />
      <Dropdown overlay={dropdownMenu}>
        <button type="button" className={s.user}>
          {avatar}
          <span className={s.user_name}>{userName}</span>
          <ArrowIcon />
        </button>
      </Dropdown>
    </div>
  );
};

export default UserMenu;
