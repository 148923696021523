import config from '../config/app';

const wsConnection = (): Promise<WebSocket> =>
  new Promise((resolve, reject) => {
    const ws = new WebSocket(config.wsHost);

    ws.onopen = () => {
      resolve(ws);
    };

    ws.onerror = (event) => {
      reject(event);
    };
  });

export default wsConnection;
