import React, { Suspense, useEffect } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Spin } from 'antd';
import * as Sentry from '@sentry/react';

import { routes } from './config/routes';
import OverviewPage from './containers/OverviewPage/Loadable';
import TransactionsPage from './containers/Transactions/Loadable';
import {
  ProductsPage,
  IndexesPage,
  IndexPage,
} from './containers/Products/Loadable';
import PortfolioPage from './containers/Portfolio/Loadable';
import UserPage from './containers/User/Loadable';
import ReferralsRewardsPage from './containers/ReferralsRewards/Loadable';
import Layout from './components/Layout';
import ModalProvider from './containers/ModalProvider';
import { useAppDispatch, useAppSelector } from './redux/store';
import config from './config/app';
import {
  makeSelectUserCurrency,
  makeSelectUserId,
} from './containers/User/selectors';
import { eventGTM } from './services/analytics/gtm';

const AppRouter = () => {
  const dispatch = useAppDispatch();
  const [cookies] = useCookies([config.tokenKey]);
  const currency = useAppSelector(makeSelectUserCurrency());
  const userId = useAppSelector(makeSelectUserId());

  useEffect(() => {
    if (cookies[config.tokenKey]) {
      dispatch({
        type: 'LOAD_APP',
        payload: { currency },
      });
    } else {
      window.location.replace(`${config.mainHost}/login`);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      eventGTM({
        event: 'login',
        userId,
      });
    }
  }, [userId]);

  return (
    <Router>
      <Layout>
        <Suspense fallback={<Spin size="large" />} />
        <Sentry.ErrorBoundary>
          <Switch>
            <Route exact path={routes.overview.path} component={OverviewPage} />
            <Route
              exact
              path={routes.transactions.path}
              component={TransactionsPage}
            />
            <Route
              exact
              path={routes.myPortfolio.path}
              component={PortfolioPage}
            />
            <Route
              exact
              path={`${routes.products.path}`}
              component={ProductsPage}
            />
            <Route exact path={routes.indexes.path} component={IndexesPage} />
            <Route
              exact
              path={routes.currentIndex.path}
              component={IndexPage}
            />
            <Route path="/user" component={UserPage} />
            <Route
              exact
              path={routes.rewards.path}
              component={ReferralsRewardsPage}
            />
          </Switch>
          <ModalProvider />
        </Sentry.ErrorBoundary>
      </Layout>
    </Router>
  );
};

export default AppRouter;
