import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Input, Select } from 'antd';

import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { makeSelectAddFundsModal } from '../../../ModalProvider/selectors';
import { makeSelectUserIsVerified } from '../../../User/selectors';
import { modalActions } from '../../../ModalProvider/slice';
import QRCode from '../../../../components/QRCode';
import CopyButton from '../../../../components/CopyButton';
import NotVerifiedUser from '../../../User/components/NotVerifiedUser';
import { makeSelectFunds } from '../../selectors';
import { FundName } from '../../types';

import s from './AddFundsModal.module.less';

const { Option } = Select;

const AddFundsModal = () => {
  const dispatch = useAppDispatch();
  const inputRef = useRef(null);
  const { visible, fund } = useAppSelector(makeSelectAddFundsModal());
  const funds = useAppSelector(makeSelectFunds());
  const [currentFund, setCurrentFund] = useState<FundName>('BTC');
  const userIsVerified = useAppSelector(makeSelectUserIsVerified());

  useEffect(() => {
    if (fund) {
      setCurrentFund(fund);
    }
  }, [fund]);

  const onClose = () => {
    dispatch(modalActions.open({ name: 'addFunds', data: { visible: false } }));
    setCurrentFund('BTC');
  };

  const address = useMemo(
    () =>
      funds[currentFund].wallet ? (
        <>
          <QRCode text={funds[currentFund].wallet} />
          <Input
            ref={inputRef}
            value={funds[currentFund].wallet}
            readOnly
            suffix={<CopyButton elementRef={inputRef} />}
          />
        </>
      ) : null,
    [currentFund, funds]
  );

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      className={s.container}
      width="100%"
      title="Add Funds"
      destroyOnClose
    >
      {userIsVerified ? (
        <>
          {address}
          <div className={s.way}>
            <span>Choose your way</span>
            <Select
              className={s.select}
              defaultValue={currentFund}
              onSelect={(value) => setCurrentFund(value)}
            >
              {Object.values(funds).map((item) => (
                <Option key={item.symbol} value={item.symbol}>
                  <div className={s.select_item}>
                    <img src={item.icon} alt={item.symbol} />
                    <span>{item.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </div>
          <p className={s.descr}>
            You will receive notifications of the transaction status in your
            email.
            <br />
            Don’t worry, you will receive your funds less than 24 hours!
          </p>
        </>
      ) : (
        <NotVerifiedUser text="You have to verify your account to be able to add funds." />
      )}
    </Modal>
  );
};

export default AddFundsModal;
