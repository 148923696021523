import { parsePhoneNumberFromString } from 'libphonenumber-js';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const formatNumber = (
  number: number,
  maxFractionDigits: number = 4
): string =>
  Number(number).toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: maxFractionDigits,
  });

export const formatCurrencyNumber = (
  number: number,
  withCents: boolean = true,
  currency: string = 'USD'
): string =>
  Number(number).toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: withCents ? 2 : 0,
    maximumFractionDigits: withCents ? 2 : 0,
  });

export const formatPercentNumber = (number: number): string =>
  (Number(number) / 100).toLocaleString('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

export const formatLastValueProductDate = (timestamp: number) => {
  const date = new Date(timestamp);

  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  });
};

export const formatChartDate = (
  timestamp: number,
  options?: Intl.DateTimeFormatOptions
) => {
  const date = new Date(timestamp);

  return date.toLocaleString(
    'en-US',
    options || {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }
  );
};

export const formatTxDate = (timestamp: number) => {
  const date = new Date(timestamp);

  return date
    .toLocaleString('en-US', {
      hour12: false,
    })
    .split(', ');
};

export const getHoldTimeDays = (timestamp: number) => {
  const now = Date.now();
  const days = (now - timestamp) / (1000 * 60 * 60 * 24);
  return days < 0 ? 0 : Math.floor(days);
};

export const formatPhone = (phone: string) => phone.replace(/^|\D/g, '');

export const formatInternationalPhone = (phone: string) =>
  parsePhoneNumberFromString(phone)?.formatInternational();

export const getUtcDate = () => {
  const now = new Date();
  return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
};

export const getTimeFromToday = (date: Date | string) =>
  dayjs(date).from(getUtcDate());

export const formatNumberToAbsolute = (number: number) => {
  if (number < 0) {
    return number * -1;
  }
  return number;
};
