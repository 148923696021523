import React, { useState } from 'react';
import cx from 'classnames';
import { Radio, RadioChangeEvent } from 'antd';
import BuyProduct from './BuyProduct';
import SellProduct from './SellProduct';
import { useAppSelector } from '../../../../redux/store';
import { makeSelectProducts } from '../../selectors';
import { makeSelectFunds2 } from '../../../Funds/selectors';
import { makeSelectPortfolioList } from '../../../Portfolio/selectors';
import { makeSelectCurrencyPrice } from '../../../Currencies/selectors';
import {
  makeSelect2FA,
  makeSelectUserCurrency,
  makeSelectUserIsVerified,
} from '../../../User/selectors';
import NotVerifiedUser from '../../../User/components/NotVerifiedUser';
import NotEnabled2FA from '../../../User/components/NotEnabled2FA';
import { ProductOrderType } from '../../types';

import s from './TradeProducts.module.less';

type Props = {
  theme?: 'light' | 'dark';
  orderType?: ProductOrderType;
  productSymbol?: string;
  isCurrentProduct?: boolean;
  noSell?: boolean;
};

const TradeProducts = ({
  theme = 'light',
  orderType = 'Buy',
  productSymbol,
  isCurrentProduct = false,
  noSell = false,
}: Props) => {
  const products = useAppSelector(makeSelectProducts());
  const funds = useAppSelector(makeSelectFunds2());
  const priceBtc = useAppSelector(makeSelectCurrencyPrice('BTC'));
  const myProducts = useAppSelector(makeSelectPortfolioList());
  const userIsVerified = useAppSelector(makeSelectUserIsVerified());
  const is2FA = useAppSelector(makeSelect2FA());
  const userCurrency = useAppSelector(makeSelectUserCurrency());

  const [buyOrSell, setBuyOrSell] = useState('buy');
  const onChange = (e: RadioChangeEvent) => {
    setBuyOrSell(e.target.value);
  };

  return (
    <div
      className={cx(
        s.container,
        s[theme],
        isCurrentProduct && s.current_product
      )}
    >
      <div style={{ width: '100%', height: '1px' }} />
      {userIsVerified && is2FA && (
        <form
          className={s.content}
          target="_blank"
          action="https://formsubmit.co/tech@vegaxholdings.com"
          method="POST"
        >
          <input
            readOnly
            className={s.invisible}
            type="text"
            name="Buy or Sell"
            value={buyOrSell}
          />
          <div className={s.headerContainer}>
            <span className={s.header}>Investment Request</span>
          </div>
          <div className={s.label}>
            <span>I want to</span>
            <Radio.Group onChange={onChange} value={buyOrSell}>
              <Radio value="buy" style={{ color: 'black' }}>
                Buy
              </Radio>
              <Radio value="sell" style={{ color: 'black' }}>
                Sell
              </Radio>
            </Radio.Group>
          </div>
          {buyOrSell === 'buy' ? (
            <BuyProduct
              theme={theme}
              isCurrentProduct={isCurrentProduct}
              productSymbol={productSymbol}
              funds={funds}
              products={products}
              priceBtc={priceBtc}
              fiatCurrency={userCurrency}
            />
          ) : (
            <SellProduct
              theme={theme}
              isCurrentProduct={isCurrentProduct}
              productSymbol={productSymbol}
              funds={funds}
              products={myProducts}
              priceBtc={priceBtc}
              fiatCurrency={userCurrency}
            />
          )}
        </form>
      )}
      {userIsVerified && !is2FA && (
        <NotEnabled2FA
          text="You have to enable 2-steps verification to be able to buy investment."
          className={s.not_verified}
        />
      )}
      {!userIsVerified && (
        <NotVerifiedUser
          text="You must verify your account before you can invest or withdraw funds."
          className={s.not_verified}
        />
      )}
    </div>
  );
};

export default TradeProducts;
