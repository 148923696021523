import { END, EventChannel, eventChannel } from 'redux-saga';
import { all, apply, call, fork, put, take } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';
import { camelizeKeys } from 'humps';

import wsConnection from '../../services/wsConnection';
import config from '../../config/app';
import { notificationsActions } from './slice';
import { Notification, WSResponse } from './types';

function createSocketChannel(ws: WebSocket) {
  return eventChannel((emit) => {
    ws.onmessage = (event) => {
      if (event.data) {
        try {
          const res = JSON.parse(event.data);
          const data = camelizeKeys(res, (key, convert) =>
            /^[A-Z0-9_]+$/.test(key) ? key : convert(key)
          );
          emit(data);
        } catch (e) {
          console.error(e.message);
        }
      }
    };

    const pingInterval = setInterval(() => {
      ws.send(JSON.stringify({ method: 'ping' }));
    }, 15000);

    ws.onclose = () => {
      clearInterval(pingInterval);
      emit('close');
      emit(END);
    };

    const cookies = new Cookies();

    const token = cookies.get(config.tokenKey);

    if (token) {
      ws.send(JSON.stringify({ token }));
      ws.send(JSON.stringify({ method: 'getAll' }));
    }

    return () => {
      ws.onmessage = null;
      ws.close(1000);
    };
  });
}

function* sendWsMessageSaga(socket: WebSocket): any {
  try {
    while (true) {
      const { payload } = yield take('WS_MESSAGE_SEND');
      yield apply(socket, socket.send, [JSON.stringify(payload)]);
    }
  } catch (e) {
    console.error(e.message);
  }
}

function* receiveMessageSaga(socketChannel: EventChannel<Event>) {
  try {
    while (true) {
      const res:
        | WSResponse<'pong' | 'success' | Notification | Notification[]>
        | 'close' = yield take(socketChannel);

      if (res === 'close') {
        yield fork(watchSocket);
      }

      if (res !== 'close' && !res.error) {
        switch (res.method) {
          case 'getAll':
            yield put(
              notificationsActions.setNotifications(
                res.result as Notification[]
              )
            );
            break;
          case 'notification':
            yield put(
              notificationsActions.addNotification(res.result as Notification)
            );
            break;
          case 'seen':
            yield put(
              notificationsActions.seenNotification(res.result as Notification)
            );
            break;
          case 'readAll':
            yield put(notificationsActions.readAll());
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
}

function* watchSocket(): any {
  const socket = yield call(wsConnection);
  const socketChannel = yield call(createSocketChannel, socket);
  yield all([
    fork(sendWsMessageSaga, socket),
    fork(receiveMessageSaga, socketChannel),
  ]);
}

export default function* notificationsSaga() {
  yield all([fork(watchSocket)]);
}
