// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotEnabled2FA-module__container--3QrFR {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.NotEnabled2FA-module__container--3QrFR p {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-size: 14px;\n  font-weight: 600;\n  text-align: center;\n}\n.NotEnabled2FA-module__container--3QrFR p img {\n  width: 25px;\n  margin-bottom: 25px;\n}\n.NotEnabled2FA-module__container--3QrFR button {\n  margin-top: 25px;\n  width: 150px;\n}\n", "",{"version":3,"sources":["webpack://src/containers/User/components/NotEnabled2FA/NotEnabled2FA.module.less"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAAF;AAHA;EAMI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;AAXA;EAcM,WAAA;EACA,mBAAA;AAAN;AAfA;EAoBI,gBAAA;EACA,YAAA;AAFJ","sourcesContent":["\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  p {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    font-size: 14px;\n    font-weight: 600;\n    text-align: center;\n\n    img {\n      width: 25px;\n      margin-bottom: 25px;\n    }\n  }\n\n  button {\n    margin-top: 25px;\n    width: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NotEnabled2FA-module__container--3QrFR"
};
export default ___CSS_LOADER_EXPORT___;
