import React, { useEffect, useState } from 'react';
import { Button, Input, Select } from 'antd';
import cx from 'classnames';

import ChangeAmountBtns from '../../../../components/ChangeAmountBtns';
import TradingPeriod from '../TradingPeriod';
import { formatCurrencyNumber, formatNumber } from '../../../../utils/format';
import { isInteger, isVusdcOrVdarc } from '../../../../utils/validate';
import { modalActions } from '../../../ModalProvider/slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  makeSelectProductsLogo,
  makeSelectStrategyIsTrading,
} from '../../selectors';
import { PortfolioProduct } from '../../../Portfolio/types';
import { FundType } from '../../../Funds/types';
import { ProductOrder } from '../../types';
import {
  makeSelectUserId,
  makeSelectUserName,
  makeSelectEmail,
} from '../../../User/selectors';

import s from './TradeProducts.module.less';
import attentionImg from '../../../../assets/icons/attention-2.svg';
import { FiatCurrencies } from '../../../Currencies/types';

const { Option } = Select;
const MIN_AMOUNT_BTC = Number(process.env.REACT_APP_SELL_MIN_AMOUNT_BTC);

type Props = {
  theme: 'light' | 'dark';
  productSymbol?: string;
  isCurrentProduct?: boolean;
  funds: FundType[];
  products: PortfolioProduct[];
  priceBtc: number;
  fiatCurrency: FiatCurrencies;
};

const SellProduct = ({
  isCurrentProduct,
  theme,
  productSymbol,
  funds,
  products,
  priceBtc,
  fiatCurrency,
}: Props) => {
  const dispatch = useAppDispatch();
  const productsLogo = useAppSelector(makeSelectProductsLogo());
  const [depositTo, setDepositTo] = useState('BTC');
  const [currentFund, setCurrentFund] = useState<FundType>(funds[0]);
  const [currentProduct, setCurrentProduct] = useState<
    PortfolioProduct | undefined
  >(undefined);
  const trading = useAppSelector(
    makeSelectStrategyIsTrading(currentProduct?.symbol || '', 'Sell')
  );
  const [amount, setAmount] = useState('');
  const [estimatedAmount, setEstimatedAmount] = useState(0);
  const [amountInFiat, setAmountInFiat] = useState(0);
  const [percentageOfBalance, setPercentageOfBalance] = useState<number | null>(
    null
  );

  const userId = useAppSelector(makeSelectUserId());
  const userName = useAppSelector(makeSelectUserName());
  const userEmail = useAppSelector(makeSelectEmail());

  useEffect(() => {
    if (productSymbol) {
      setCurrentProduct(products.find((p) => p.symbol === productSymbol));
    } else {
      setCurrentProduct(products[0]);
    }
  }, [products, productSymbol]);

  useEffect(() => {
    if (percentageOfBalance && currentProduct) {
      const productAmount =
        currentProduct.symbol === 'VUSDC'
          ? currentProduct.amountWithProfit
          : currentProduct.amount;
      setAmount(((productAmount * percentageOfBalance) / 100).toFixed());
    }
  }, [currentProduct, percentageOfBalance]);

  useEffect(() => {
    if (currentProduct) {
      setAmountInFiat(
        Math.floor(parseFloat(amount || '0')) * currentProduct.valueInCurrency
      );
    }
  }, [amount, currentProduct]);

  useEffect(() => {
    setEstimatedAmount(
      parseFloat((amountInFiat / currentFund.price).toFixed(8))
    );
  }, [amountInFiat, currentFund]);

  useEffect(() => {
    if (currentProduct) {
      const fund = funds.find((f) => f.symbol === currentProduct.baseCurrency);
      if (fund) {
        setDepositTo(fund.symbol);
      } else {
        setDepositTo(funds[0].symbol);
      }
    }
  }, [currentProduct, funds]);

  useEffect(() => {
    const searchFund =
      depositTo === 'VUSDC' ? currentProduct?.baseCurrency : depositTo;
    const fund = funds.find((f) => f.symbol === searchFund);
    setCurrentFund(fund || funds[0]);
  }, [currentProduct?.baseCurrency, depositTo, funds]);

  const minAmount = parseFloat(
    ((MIN_AMOUNT_BTC * priceBtc) / currentFund.price).toFixed(
      currentFund.symbol === 'VUSDC' ? 0 : 8
    )
  );

  const isErrorMin = estimatedAmount < minAmount;

  const disabled =
    !amount ||
    !currentProduct ||
    parseFloat(amount) >
      (currentProduct.symbol === 'VUSDC'
        ? currentProduct.amountWithProfit
        : currentProduct.amount);

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isInteger(e.target.value)) return;
    setAmount(e.target.value);
    if (percentageOfBalance) {
      setPercentageOfBalance(null);
    }
  };

  // const onSellClick = () => {
  //   if (currentProduct && !disabled) {
  //     const order: ProductOrder = {
  //       type: 'Sell',
  //       product: currentProduct.symbol,
  //       amount: parseFloat(amount),
  //       currency: currentFund.symbol,
  //       currencyAmount: estimatedAmount,
  //       fiatAmount: amountInFiat,
  //       created: currentProduct.created,
  //     };

  //     if (depositTo === 'VUSDC') {
  //       order.productForExchange = depositTo;
  //     }

  //     dispatch(
  //       modalActions.open({
  //         name: 'confirmTx',
  //         data: {
  //           visible: true,
  //           order,
  //         },
  //       })
  //     );
  //   }
  // };

  return (
    <>
      <input
        readOnly
        className={s.invisible}
        type="text"
        name="User Id"
        value={userId}
      />
      <input
        readOnly
        className={s.invisible}
        type="text"
        name="User Name"
        value={userName}
      />
      <input
        readOnly
        className={s.invisible}
        type="text"
        name="User Email"
        value={userEmail}
      />
      <input
        readOnly
        className={s.invisible}
        type="text"
        name="Investment product"
        value={currentProduct?.symbol}
      />
      <input
        readOnly
        className={s.invisible}
        type="text"
        name="Investment Sell Amount"
        value={amount}
      />
      <input
        readOnly
        className={s.invisible}
        type="text"
        name="Fund type"
        value={currentFund.symbol}
      />

      {!isCurrentProduct && (
        <div className={s.label}>
          <span>Sell</span>
          <Select
            className={s.select}
            value={currentProduct?.symbol}
            // @ts-ignore
            onChange={(value, { product }: { product: PortfolioProduct }) =>
              setCurrentProduct(product)
            }
          >
            {products.map((product) => (
              <Option
                key={product.symbol}
                value={product.symbol}
                product={product}
              >
                <div className={s.select_item}>
                  <img
                    src={productsLogo[product.symbol]}
                    alt={product.symbol}
                  />
                  <span>
                    {formatNumber(
                      product.symbol === 'VUSDC'
                        ? product.amountWithProfit
                        : product.amount
                    )}{' '}
                    {product.symbol}
                  </span>
                </div>
              </Option>
            ))}
          </Select>
          <Input
            className={s.input}
            placeholder="Enter amount"
            addonAfter={currentProduct?.symbol ?? 'Select'}
            bordered={true}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
      )}

      <div className={s.label}>
        <span>Deposit to</span>
        <Select className={s.select} value={depositTo} onChange={setDepositTo}>
          {funds.map((item) => (
            <Option
              key={item.symbol}
              value={item.symbol}
              disabled={
                item.symbol !== currentProduct?.baseCurrency &&
                !isVusdcOrVdarc(currentProduct?.symbol)
              }
            >
              <div className={s.select_item}>
                <img src={item.icon} alt={item.symbol} />
                <span>{item.name}</span>
              </div>
            </Option>
          ))}
        </Select>
      </div>

      <Button
        type="primary"
        htmlType="submit"
        shape="round"
        ghost={theme === 'dark'}
        block
        disabled={disabled}
      >
        SEND REQUEST
      </Button>
    </>
  );
};

export default SellProduct;
