// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.ant-modal.OtpModal-module__container--3Hfbb {\n  max-width: 464px;\n}\n.OtpModal-module__content--1RIh5 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: #000;\n}\n.OtpModal-module__content--1RIh5 h2 {\n  margin-top: 15px;\n  color: #72A0C7;\n}\n.OtpModal-module__content--1RIh5 p {\n  margin-top: 10px;\n}\n.OtpModal-module__content--1RIh5 button {\n  min-width: 150px;\n  margin-top: 25px;\n}\n.OtpModal-module__content--1RIh5 a {\n  margin-top: 25px;\n  color: #72A0C7;\n  font-size: 10px;\n  font-weight: 300;\n}\n", "",{"version":3,"sources":["webpack://src/containers/User/components/OtpModal/OtpModal.module.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAC3F;EACE,gBAAA;AACJ;AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;AADF;AAHA;EAOI,gBAAA;EACA,cAAA;AADJ;AAPA;EAYI,gBAAA;AAFJ;AAVA;EAgBI,gBAAA;EACA,gBAAA;AAHJ;AAdA;EAqBI,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AAJJ","sourcesContent":["@import \"../../../../theme/variables\";\n\n\n:global(.ant-modal) {\n  &.container {\n    max-width: 464px;\n  }\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: @black;\n\n  h2 {\n    margin-top: 15px;\n    color: @paragraph;\n  }\n\n  p {\n    margin-top: 10px;\n  }\n\n  button {\n    min-width: 150px;\n    margin-top: 25px;\n  }\n\n  a {\n    margin-top: 25px;\n    color: @paragraph;\n    font-size: 10px;\n    font-weight: 300;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OtpModal-module__container--3Hfbb",
	"content": "OtpModal-module__content--1RIh5"
};
export default ___CSS_LOADER_EXPORT___;
