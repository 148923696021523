import React from 'react';
import cx from 'classnames';

import InfoTooltip from '../../../../components/InfoTooltip';
import { ProductOrderType } from '../../types';

import s from './TradingPeriod.module.less';

type Props = {
  isActive: boolean;
  type: ProductOrderType;
  withTooltip?: boolean;
  withBorder?: boolean;
};

const Link: React.FC = ({ children }) => (
  <a
    href="https://vegaxholdings.medium.com/vegax-guide-active-inactive-periods-2b7b1ee1a609"
    target="_blank"
    rel="noreferrer"
  >
    {children}
  </a>
);

const TradingPeriod = ({
  isActive,
  type,
  withTooltip = true,
  withBorder = true,
}: Props) => {
  let info = (
    <>
      Investment is not processed. For options strategies like VEBE/VEEE, the
      status will change to "Active" when the options are officially
      written/sold. See more about active/inactive period <Link>here</Link>.
    </>
  );

  if (isActive && type === 'Buy') {
    info = (
      <>
        Investment is processed and connected to strategy. For options
        strategies like VEBE/VEEE, the status will reflect "Active" when the
        options are officially written/sold. The "Active" period for buying
        strategies is the first seven days of every month. For example, if it is
        the 15th of January now, status would be changed to "Active" from the
        1st to the 7th of February. See more about active/inactive period{' '}
        <Link>here</Link>.
      </>
    );
  }

  if (isActive && type === 'Sell') {
    info = (
      <>
        Investment is processed and connected to strategy. For options
        strategies like VEBE/VEEE, the status will reflect "Active" when the
        options are officially written/sold. The "Active" period for selling
        strategies is the last seven days of every month. For example, if it is
        15th January now, status would be changed to "Active" from the 25th to
        the 31st of January. See more about active/inactive period{' '}
        <Link>here</Link>.
      </>
    );
  }

  return (
    <div
      className={cx(s.container, isActive ? s.active : s.inactive)}
      style={{ borderWidth: withBorder ? '1px' : '0' }}
    >
      {isActive ? 'Active' : 'Inactive'}
      {withTooltip && <InfoTooltip placement="bottom">{info}</InfoTooltip>}
    </div>
  );
};

export default TradingPeriod;
