/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Input, Select, Tooltip, Radio, RadioChangeEvent } from 'antd';
import cx from 'classnames';

import ChangeAmountBtns from '../../../../components/ChangeAmountBtns';
import TradingPeriod from '../TradingPeriod';
import { formatCurrencyNumber, formatNumber } from '../../../../utils/format';
import { isDecimal, isVusdcOrVdarc } from '../../../../utils/validate';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { makeSelectStrategyIsTrading } from '../../selectors';
import {
  makeSelectUserFromUSA,
  makeSelectUserId,
  makeSelectUserName,
  makeSelectEmail,
} from '../../../User/selectors';
import { modalActions } from '../../../ModalProvider/slice';
import { Strategy } from '../../types';
import { FundType } from '../../../Funds/types';
import { FiatCurrencies } from '../../../Currencies/types';

import s from './TradeProducts.module.less';
import defaultLogo from '../../../../assets/images/default-token-logo.svg';
import attentionImg from '../../../../assets/icons/attention-2.svg';
import './index.css';

const { Option } = Select;

const MIN_AMOUNT_BTC = Number(process.env.REACT_APP_BUY_MIN_AMOUNT_BTC);
const PRODUCTS_FOR_USA = ['VEBE', 'VEEE', 'VUSDC'];

type Props = {
  theme: 'light' | 'dark';
  productSymbol?: string;
  isCurrentProduct?: boolean;
  funds: FundType[];
  products: Strategy[];
  priceBtc: number;
  fiatCurrency: FiatCurrencies;
};

const BuyProduct = ({
  isCurrentProduct,
  theme,
  productSymbol,
  funds,
  products,
  priceBtc,
  fiatCurrency,
}: Props) => {
  const [amount, setAmount] = useState<number>(0);
  const [currentFund, setCurrentFund] = useState<FundType>(funds[0]);
  const [currentProduct, setCurrentProduct] = useState<Strategy | undefined>(
    undefined
  );
  const userFromUSA = useAppSelector(makeSelectUserFromUSA());
  const userId = useAppSelector(makeSelectUserId());
  const userName = useAppSelector(makeSelectUserName());
  const userEmail = useAppSelector(makeSelectEmail());

  useEffect(() => {
    if (productSymbol) {
      setCurrentProduct(products.find((p) => p.symbol === productSymbol));
    } else {
      setCurrentProduct(products[0]);
    }
  }, [products, productSymbol]);

  useEffect(() => {
    if (currentProduct) {
      const baseFund = funds.find(
        (fund) => fund.symbol === currentProduct.baseCurrency
      );
      if (baseFund) {
        setCurrentFund(baseFund);
      }
    }
  }, [currentProduct, funds]);

  const doNotBuy = useMemo(
    () =>
      userFromUSA &&
      !PRODUCTS_FOR_USA.find((p) => p === currentProduct?.symbol),
    [currentProduct?.symbol, userFromUSA]
  );

  const disabled =
    doNotBuy ||
    !currentProduct?.symbol ||
    !currentFund.symbol ||
    amount === 0 ||
    currentFund?.amount < amount;

  const btn = (
    <Button
      type="primary"
      htmlType="submit"
      shape="round"
      ghost={theme === 'dark'}
      block
      disabled={disabled}
    >
      SEND REQUEST
    </Button>
  );

  return (
    <>
      <input
        readOnly
        className={s.invisible}
        type="text"
        name="User Id"
        value={userId}
      />
      <input
        readOnly
        className={s.invisible}
        type="text"
        name="User Name"
        value={userName}
      />
      <input
        readOnly
        className={s.invisible}
        type="text"
        name="User Email"
        value={userEmail}
      />
      <input
        readOnly
        className={s.invisible}
        type="text"
        name="Investment product"
        value={currentProduct?.symbol}
      />
      <input
        readOnly
        className={s.invisible}
        type="text"
        name="Fund type"
        value={currentFund.symbol}
      />
      <input
        readOnly
        className={s.invisible}
        type="text"
        name="Fund Payment Amount"
        value={amount}
      />
      {!isCurrentProduct && (
        <div className={s.label}>
          <span>Investment product</span>
          <Select
            className={s.select}
            value={currentProduct?.symbol}
            // @ts-ignore
            onChange={(value, { product }: { product: Strategy }) =>
              setCurrentProduct(product)
            }
          >
            {products.map((product) => (
              <Option
                key={product.symbol}
                value={product.symbol}
                product={product}
              >
                <div className={s.select_item}>
                  <img src={product.files.logo || defaultLogo} alt="logo" />
                  <span>{product.symbol}</span>
                </div>
              </Option>
            ))}
          </Select>
        </div>
      )}
      <div className={s.label}>
        <span>Take from deposit</span>
        <Select
          className={s.select}
          value={currentFund.symbol}
          // @ts-ignore
          onChange={(value, { fund }: { fund: FundType }) =>
            setCurrentFund(fund)
          }
        >
          {funds.map((item) => (
            <Option
              key={item.symbol}
              value={item.symbol}
              fund={item}
              disabled={
                item.symbol !== currentProduct?.baseCurrency &&
                !isVusdcOrVdarc(currentProduct?.symbol)
              }
            >
              <div className={s.select_item}>
                <img src={item.icon} alt={item.symbol} />
                <span>
                  {formatNumber(item.amount)} {item.symbol}
                </span>
              </div>
            </Option>
          ))}
        </Select>
        <Input
          className={s.input}
          placeholder="Enter amount"
          addonAfter={currentFund?.symbol}
          bordered={true}
          onChange={(e) => setAmount(+e.target.value)}
        />
      </div>
      {btn}
    </>
  );
};

export default BuyProduct;
