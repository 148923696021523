import React from 'react';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

import AppRouter from './AppRouter';
import { store } from './redux/store';
import MediaQueryProvider from './contexts/MediaQueryProvider';

function App() {
  return (
    <Provider store={store}>
      <CookiesProvider>
        <MediaQueryProvider>
          <AppRouter />
        </MediaQueryProvider>
      </CookiesProvider>
    </Provider>
  );
}

export default App;
